import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Navigation.css"; // Import the CSS file
import LogoImg from "../assets/Logo.png";
import CubeIcon from "../assets/Cube.png";

const Navigation = () => {
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const [aboutAnchorEl, setAboutAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleServicesMenuOpen = (event) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleServicesMenuClose = () => {
    setServicesAnchorEl(null);
  };

  const handleAboutMenuOpen = (event) => {
    setAboutAnchorEl(event.currentTarget);
  };

  const handleAboutMenuClose = () => {
    setAboutAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContents = (
    <List>
      <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/services/overview" onClick={toggleDrawer(false)}>
        <ListItemText primary="Services Overview" />
      </ListItem>
      <ListItem button component={Link} to="/services/strategic-consulting" onClick={toggleDrawer(false)}>
        <ListItemText primary="Strategic Consulting" />
      </ListItem>
      <ListItem button component={Link} to="/services/data-governance" onClick={toggleDrawer(false)}>
        <ListItemText primary="Data Governance" />
      </ListItem>
      <ListItem button component={Link} to="/services/technology-consulting" onClick={toggleDrawer(false)}>
        <ListItemText primary="Technology Consulting" />
      </ListItem>
      <ListItem button component={Link} to="/about/contact" onClick={toggleDrawer(false)}>
        <ListItemText primary="Contact Us" />
      </ListItem>
      <ListItem button component={Link} to="/about/philanthropy" onClick={toggleDrawer(false)}>
        <ListItemText primary="Philanthropy" />
      </ListItem>
      <ListItem button component={Link} to="/about/mentoring-internship" onClick={toggleDrawer(false)}>
        <ListItemText primary="Mentoring and Internship" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "#d176db", color: "black" }}>
      <Toolbar className="nav-toolbar">
        <div className="nav-icon">
          <Link to="/">
            <img src={CubeIcon} alt="Cube Icon" className="icon-img" />
          </Link>
        </div>
        <div className="nav-logo">
          <Link to="/">
            <img src={LogoImg} alt="Logo" className="logo-img" />
          </Link>
        </div>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerContents}
            </Drawer>
          </>
        ) : (
          <div className="nav-links">
            <Button
              component={Link}
              to="/"
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "Roboto, sans-serif",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              Home
            </Button>
            <Button
              onClick={handleServicesMenuOpen}
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "Roboto, sans-serif",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              Services
            </Button>
            <Menu
              anchorEl={servicesAnchorEl}
              open={Boolean(servicesAnchorEl)}
              onClose={handleServicesMenuClose}
            >
              <MenuItem
                onClick={handleServicesMenuClose}
                component={Link}
                to="/services/overview"
              >
                Services Overview
              </MenuItem>
              <MenuItem
                onClick={handleServicesMenuClose}
                component={Link}
                to="/services/strategic-consulting"
              >
                Strategic Consulting
              </MenuItem>
              <MenuItem
                onClick={handleServicesMenuClose}
                component={Link}
                to="/services/data-governance"
              >
                Data Governance
              </MenuItem>
              <MenuItem
                onClick={handleServicesMenuClose}
                component={Link}
                to="/services/technology-consulting"
              >
                Technology Consulting
              </MenuItem>
            </Menu>
            <Button
              onClick={handleAboutMenuOpen}
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "Roboto, sans-serif",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              About Us
            </Button>
            <Menu
              anchorEl={aboutAnchorEl}
              open={Boolean(aboutAnchorEl)}
              onClose={handleAboutMenuClose}
            >
              <MenuItem
                onClick={handleAboutMenuClose}
                component={Link}
                to="/about/contact"
              >
                Contact Us
              </MenuItem>
              <MenuItem
                onClick={handleAboutMenuClose}
                component={Link}
                to="/about/philanthropy"
              >
                Philanthropy
              </MenuItem>
              <MenuItem
                onClick={handleAboutMenuClose}
                component={Link}
                to="/about/mentoring-internship"
              >
                Mentoring and Internship
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
