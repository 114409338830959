import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './Components/Navigation';
import Home from './Components/Home';
import ServicesOverview from './Components/Services/ServicesOverview';
import StrategicConsulting from './Components/Services/StrategicConsulting';
import DataGovernance from './Components/Services/DataGovernance';
import TechnologyConsulting from './Components/Services/TechnologyConsulting';
import ContactUs from './Components/About/ContactUs';
import Philanthropy from './Components/About/Philanthropy';
import MentoringInternship from './Components/About/MentoringInternship';
import './App.css'; // Import your CSS file for styles
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
    <Navigation />
      <div className="app-container">
        {/* <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div> */}
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services/overview" element={<ServicesOverview />} />
            <Route path="/services/strategic-consulting" element={<StrategicConsulting />} />
            <Route path="/services/data-governance" element={<DataGovernance />} />
            <Route path="/services/technology-consulting" element={<TechnologyConsulting />} />
            <Route path="/about/contact" element={<ContactUs />} />
            <Route path="/about/philanthropy" element={<Philanthropy />} />
            <Route path="/about/mentoring-internship" element={<MentoringInternship />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;