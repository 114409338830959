import React from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Grow,
} from "@mui/material";
import HomeHero from "../assets/Home1.png";
import Home2 from "../assets/Home2.png";
import Home3 from "../assets/Home3.png";
import Home4 from "../assets/WHY.avif";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";
import { styled, keyframes } from "@mui/system";

const growAnimation = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

const AnimatedButton = styled(Button)(({ theme }) => ({
  animation: `${growAnimation} 0.5s ease-in-out`,
  "&:hover": {
    animation: `${growAnimation} 0.3s ease-in-out`,
  },
}));

const Home = () => {
  return (
    <>
      <Box sx={{ textAlign: "center", mt: { xs: 4, md: 6 } }}>
        <img src={Logo} alt="Logo" style={{ maxWidth: "100%", width: "500px" }} />
      </Box>
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 5 }, mt: { xs: 3, md: 5 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: "#d176db", fontSize: { xs: "32px", md: "48px" } }}
            >
              Tailored Consulting Services that Achieve Client Goals
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#577592", fontSize: { xs: "16px", md: "20px" } }}
            >
              Success through engagement and empowerment
            </Typography>
            <Grow in={true} timeout={500}>
              <AnimatedButton
                component={Link}
                to="/services/overview"
                variant="contained"
                color="primary"
                sx={{
                  mt: { xs: 3, md: 5 },
                  color: "white",
                  padding: { xs: "8px 12px", md: "10px 15px" },
                  borderRadius: "8px",
                  textAlign: "center",
                  backgroundColor: "#2294ed",
                }}
              >
                Our Services
              </AnimatedButton>
            </Grow>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={HomeHero}
              alt="Home"
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 5 }, mt: { xs: 5, md: 8 } }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ color: "#d176db", textAlign: "center", mb: 3 }}
        >
          About Us
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Typography
          variant="h4"
          component="h3"
          gutterBottom
          sx={{ fontSize: "1.5rem", mb: 2 }}
        >
          Our Story
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          At R Cube Consulting, we specialize in Process and IT consulting
          services. Formed in 2003 with a vision to service Small to Medium
          Businesses (SME), we have been successful in operating in Asia, USA,
          and UK (in limited capacity).
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We started our operations with staff augmentation assignments to
          fulfill our client needs of technology and middle & back-office
          operations. With time, client trust grew, and we were given the
          opportunity to set up a team that went on to put Corporate Health
          Economics and the world's first Personal Health Record Solutions to
          the world map. The organization also bagged the contract to build a
          product for the State of California, Bureau of Automobile Repairs
          (BAR). The Product was successfully built in collaboration with other
          consulting organizations as team members. We also operated and staffed
          consulting assignments in Asia and UK. We put our operations on hold
          during 2018.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          R Cube, relaunched in its new avatar in Aug'23. It is set to provide
          top quality business and technology consulting services to their
          clients.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We believe, with the changing landscape in Privacy laws and
          ever-tightening financial & regulatory affairs across the globe, the
          Small and Medium Business are vulnerable to regulatory risks.
          Establishing policies and proving its compliance is going to become
          necessary for all. R Cube Consulting can help navigate by sharing
          critical insights and solutions to combat this oncoming challenge.
          Today, this expertise is mostly offered by the Big 4s. Our team has
          experienced professionals who have successfully implemented Standards
          & Processes and Tools linked to Data Management & Governance for
          financial institutions for over a decade. These experiences and skills
          are portable to other industry verticals such as Healthcare, Pharma,
          Higher Education, or Insurance.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          R Cube Consulting will partner with you in addressing the business
          challenge and solving it in an economical manner that can sustain
          future growth of the organization.
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h4"
            component="h3"
            gutterBottom
            sx={{ fontSize: "1.5rem", mb: 2 }}
          >
            Our Mission
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Our mission is to provide innovative consulting solutions that
            empower businesses to thrive in a rapidly changing world. We strive
            to deliver exceptional value to our clients by leveraging our
            expertise, fostering collaboration, and maintaining a commitment to
            excellence in everything we do.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 5 }, mt: { xs: 5, md: 8 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={Home2}
              alt="Home"
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: 0 } }}>
            <Typography variant="h4" gutterBottom sx={{ color: "#d176db" }}>
              Our Services
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" gutterBottom>
              We offer consulting and staffing expertise that is tailored to
              client needs in the space of:
            </Typography>
            <List sx={{ mb: 2 }}>
              <ListItem>
                <ListItemText primary="Digital Transformations" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Customer Lifecycle Management" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Data Management & Business Intelligence Transformation" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Full Lifecycle Data Governance & Policy Framework" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Data Quality & Standardization Framework" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cloud Migration Strategy & Planning" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Information Security Audits & Remediation" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 5 }, mt: { xs: 5, md: 8 } }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom sx={{ color: "#d176db" }}>
              Our Approach
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Ownership:</strong> To understand and own the scope of
              change is vital to our Clients and Us. Our commitment to our
              clients' transformation journey is our single most critical
              success factor.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Empowerment:</strong> Increased client empowerment at each
              phase of the program helps in establishing ownership to the
              problem.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>The Human Element:</strong> Establishing healthy and
              honest relationships help foster collaboration, eventually
              mitigating program risks. Mentoring client teams and having an
              open dialogue throughout the engagement ensures little to no
              dependency during the final transition.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#a44ead", fontWeight: "bold" }}
            >
              Ownership - Risk Mitigation - Empowerment
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: 0 } }}>
            <Box
              component="img"
              src={Home3}
              alt="Home"
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 5 }, mt: { xs: 5, md: 8 } }}>
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Box
            component="img"
            src={Home4}
            alt="Home"
            sx={{
              width: { xs: "80%", md: "50%" },
              height: "auto",
              display: "block",
              mx: "auto",
            }}
          />
        </Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: "#d176db", textAlign: "center", mb: 3 }}
        >
          Why Us?
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1" paragraph>
          Our approach to problem solving & risk management has been the
          touchstone of our success. Knowing and sharing what works and what
          does not work makes each client and engagement feel satisfied with
          the final outcome.
        </Typography>
        <Typography variant="body1" paragraph>
          Why fix what is not broken?
        </Typography>
        <Typography variant="body1" paragraph>
          Client economic viability and benefits are key factors in carving
          the path to solving the problem. We leverage our internal and
          external experts to accumulate knowledge and insights to benefit
          clients' decision. We believe tooling is not the only way to solve
          every problem. Understanding factors affecting the current state and
          addressing them in a manner that is conducive for an organization to
          accept and adopt makes us a viable and preferred partner.
        </Typography>
        <Typography variant="body1" paragraph>
          Our capabilities in establishing private and public cloud platforms,
          as well as tapping into industry experts to "get it right" the first
          time, makes us an attractive proposition.
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ color: "#577592", fontSize: "20px", textAlign: "center" }}
        >
          Honesty is our best foot forward
        </Typography>
      </Box>
    </>
  );
};

export default Home;
