import React from "react";
import { Box, Typography, Card, CardContent, Link, Grid } from "@mui/material";
import { styled } from "@mui/system";
import SuPraImage from "../../assets/SupraImg.svg";
import RoomToReadImage from "../../assets/RoomToRead.webp";
import ScholarshipIcon from "../../assets/ScholarshipIcon.svg";
import EducationIcon from "../../assets/EducationIcon.svg";

const HoverCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const CenteredContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Philanthropy = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: { xs: 3, md: 5 },
        marginTop: 8,
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: 5 }}>
        <Typography variant="h4" sx={{ color: "#d176db", fontWeight: "bold" }}>
          Philanthropy
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <HoverCard sx={{ boxShadow: 3 }}>
            <CardContent>
              <CenteredContent>
                <img
                  src={SuPraImage}
                  alt="Su-Pra Scholarship"
                  style={{ width: "70%", height: "auto", marginBottom: 20 }}
                />
                <img
                  src={ScholarshipIcon}
                  alt="Scholarship Icon"
                  style={{ width: 100, height: 50, marginBottom: 20 }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#333", fontWeight: "bold", marginBottom: 3 }}
                >
                  Su-Pra Scholarship for Girls
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#666", marginBottom: 3 }}
                >
                  Su - Pra - Sunanda and Prabhakar Rao supported the idea of girl
                  child education in their respective villages. Through the years,
                  their annual contributions helped families supplement a portion
                  of their income, paving way for their daughters to continue
                  education and eventually a career. These small gestures were
                  very effective in encouraging beneficiary families to let their
                  daughters continue studies who otherwise would dropout at a
                  critical stage of their education.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#666", marginBottom: 3, textAlign: "justify" }}
                >
                  R Cube pledges to carry this idea forward by supporting Su-Pra
                  Scholarship for girls. We will contribute a portion of our
                  revenues to support girls in and beyond high school so that
                  their last mile in education leading to a career is not abruptly
                  ended due to lack of funds. A team of trustees will choose the
                  scholars and help them in their path to success.
                </Typography>
              </CenteredContent>
            </CardContent>
          </HoverCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <HoverCard sx={{ boxShadow: 3 }}>
            <CardContent>
              <CenteredContent>
                <img
                  src={RoomToReadImage}
                  alt="Room to Read"
                  style={{ width: "80%", height: "auto", marginBottom: 20 }}
                />
                <img
                  src={EducationIcon}
                  alt="Education Icon"
                  style={{ width: 100, height: 50, marginBottom: 20 }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#333", fontWeight: "bold", marginBottom: 3 }}
                >
                  Room to Read
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#666", marginBottom: 3, textAlign: "justify" }}
                >
                  R Cube also supports Room to Read, an international organization
                  helping build classrooms in Asia and Africa. This organization
                  has been very successful in creating a movement that touches the
                  community at grassroots. For more information on Room to Read,
                  kindly visit -
                </Typography>
                <Link
                  href="https://www.roomtoread.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#d176db", fontWeight: "bold" }}
                >
                  Room to Read
                </Link>
              </CenteredContent>
            </CardContent>
          </HoverCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Philanthropy;
