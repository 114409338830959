import React from 'react';
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import StandardizationIcon from '@mui/icons-material/AssignmentTurnedIn';
import MetadataIcon from '@mui/icons-material/Description';
import QualityIcon from '@mui/icons-material/ThumbUp';
import ToolsIcon from '@mui/icons-material/Build';
import ManagementIcon from '@mui/icons-material/AccountTree';

const DataGovernance = () => {
  const services = [
    {
      title: 'Vision and Policy',
      description: `Executive Vision drives shaping of the Data Governance ecosystem. Keeping the end in mind, the vision takes shape with establishment of drivers, goals and objectives that guide the initiative. Definition of roles and the interaction model conducive to organization and its culture is paramount.
      Our team has experts who can conduct workshops for C-level executives and other stakeholders to formulate the vision statement, establish a policy framework that guides the objectives and a roadmap with measurable outcomes and impact.`,
      icon: <PolicyIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Data Governance Process Standardization',
      description: `Executive vision drives policies that dictate data hygiene and shapes role-based interaction models. Who, What, Where, When and How are determined and documented, standardizing the process framework and interaction model. Training programs are designed and imparted to targeted audiences by roles. Piloting a Use Case ratifies the entire DG process value chain per documented process and standards.
      Our team specializes in creation of customized DG processes and standards that work well with the organization and also satisfies any regulatory obligation as applicable. We partner with client stakeholders to educate and empower them in the process.`,
      icon: <StandardizationIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Metadata Management and Stewardship',
      description: `Effective Metadata Management is critical for any Data Governance program. To have clarity in understanding Process, Applications, People (Roles) supporting and Data that make the process come alive are central to a governance and its certification process. Established Data Lineage and Allied Assets helps in clear and precise narration to the regulators' inquiries.
      R Cube Consulting has experts who can facilitate the establishment of the required process by sharing insights and empowering Data Governance officers and Stewards. We help selection and implementation of industry standard tools, as well as create bespoke application platform, tailored to the clients' needs.`,
      icon: <MetadataIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Data Quality and Standardization Framework',
      description: `Establishing good quality of data throughout the enterprise is assumed by default. However, with factors such as rapid organic growth, expansion through M&A, and other business and technology practices, the risk of proliferation of data that has broken parity become high. The consequences can be seen from marketing misses to skewed balance sheets, eventually causing reputational damage.
      Enterprise Risk Mitigation can be achieved with good data quality regime. The discipline required to maintain quality information must be treated with utmost care and rigor. This objective needs multiple operating groups/departments to come together and work in unison. Data Quality assurance is the key business objective of Data Governance Operation.
      Our team has wide experience in establishing Data Quality ecosystems by shaping people roles, establishing processes & standards and introducing technology solutions for Preventive and Detective Data Quality Measurement and Reporting. We assess, share and tune the solutions that best fits the client needs.`,
      icon: <QualityIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Tools Selection and IT Implementation',
      description: `Our IT consulting and advisory services help you navigate the complex world of technology. Our team of experts can provide guidance on technology strategy, vendor selection, and project management to help you achieve your Data Governance Vision and Goals. Answers to critical questions on buy versus build are collectively arrived.`,
      icon: <ToolsIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Program Management',
      description: `Our Program Management Services have specialists in Data Governance space. These SMEs can be engaged for full life cycle programs or be brought in periodically for oversight engagements to assess and manage risks. Our objective is always to protect your business from Program and initiative breaches and offer response planning to help you stay ahead of perceived threats.`,
      icon: <ManagementIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 3, md: 5 }, marginTop: 8, backgroundColor: 'white' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#d176db', textAlign: 'center', marginBottom: 5, fontWeight: 'bold' }}
      >
        Data Governance Insights
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: '#333', textAlign: 'center', marginBottom: 5, fontWeight: 'bold' }}
      >
        Data Governance Ecosystem - Building Trust in Data, For All
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        Data Governance is considered the 2nd line of defense towards proliferation of bad information
        leading to corporate harm. A functioning, mature data governance process holds the ability to detect,
        trace and remediate potentially bad information before its propagation and use. Identifying, Classifying,
        Authorizing, Securing, and Servicing (volume, conduit, timing) are fundamental pillars for good governance.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        Industries such as Banking & Finance, Pharma, healthcare, Higher Education and many more are being subjected
        to risk of reputational damage and lawsuits amounting to millions of dollars due to breach in Privacy and
        inaccurate reporting to public and government.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        Regulatory bodies across the globe have been publishing policies that target specific industries and in
        interest of general public.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        United Nations Conference on Trade and Development (UNCTAD) classifies these regulations under:
      </Typography>
      <List sx={{ marginBottom: 3 }}>
        <ListItem>
          <ListItemText primary="Financial Crimes Laws" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Consumer Protection Laws" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Data Protection and Privacy Laws" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Cybercrime Laws" />
        </ListItem>
      </List>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        Consumer Protection Laws are applicable to most industries. Some common data protection laws are:
      </Typography>
      <List sx={{ marginBottom: 3 }}>
        <ListItem>
          <ListItemText primary="General Data Protection Regulation (GDPR): a comprehensive privacy legislation that applies across sectors and to companies of all sizes in Europe." />
        </ListItem>
        <ListItem>
          <ListItemText primary="California Consumer Privacy Act (CCPA): a state statute intended to enhance privacy rights and consumer protection for residents of the state of California in the United States." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Family Education Rights and Privacy Act (FERPA): a U.S. Federal law that protects the privacy of student education records where funds are given by U.S. Dept. Of Education." />
        </ListItem>
      </List>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        A good resource to understand laws and applicability across the globe is:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        <a href="https://unctad.org/page/data-protection-and-privacy-legislation-worldwide" target="_blank" rel="noopener noreferrer">
          Data Protection and Privacy Legislation Worldwide | UNCTAD
        </a>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
        Every system needs the following pillars to be foundationally strong:
      </Typography>
      <List sx={{ marginBottom: 3 }}>
        <ListItem>
          <ListItemText primary="People - Starting with Executive vision, shaping the team to fulfill the ask needs serious strategy and planning. Keeping the end in mind, the vision takes shape with establishment of roles and the interaction model that the organization and its culture supports." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Process - The executive vision drives policies that dictate data hygiene that shapes role-based interaction model. Who, Where, What, When and How are determined and documented." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tooling - Audit compliance and certification are some of the more important aspects of governance process. Ability to record, collaborate and report requires enterprise class tools that are flexible and robust to handle operations ranging from Business Operations to Technology SDLC, Audit and compliance ratifying the processes and eventual certification by Governance and Business alike." />
        </ListItem>
      </List>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: '#d176db', textAlign: 'center', marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}
      >
        Data Governance Services by R Cube
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: 2,
                boxShadow: 3,
                transition: 'transform 0.2s, box-shadow 0.2s',
                borderRadius: 2,
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                  {service.icon}
                </Box>
                <Typography variant="h6" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold' }}>
                  {service.title}
                </Typography>
                <Typography variant="body1" sx={{textAlign: "justify" }}>{service.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DataGovernance;
