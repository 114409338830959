import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import IntegrationIcon from '@mui/icons-material/DeviceHub';
import DataIcon from '@mui/icons-material/Storage';
import AnalyticsIcon from '@mui/icons-material/BarChart';
import DigitalTransformationIcon from '@mui/icons-material/Transform';
import CloudIcon from '@mui/icons-material/Cloud';
import ManagedServicesIcon from '@mui/icons-material/Settings';

const TechnologyConsulting = () => {
  const services = [
    {
      title: 'Systems Integration',
      description: `Seamless information flow that adheres to enterprise architecture principles and production operations needs is an ideal state for an Enterprise IT establishment. Having the traceability and insights into what, where and how of information flow allows for a safe and efficient management of data. Automated self-correction to breaks in information flow within the agreed SLAs makes for an ideal IT setup. Our Systems Integration experts believe in these principles and help organizations achieve this state for their clients. We have expertise in deploying and integrating enterprise level systems to legacy applications. We collaborate with IT teams to define the IT Operations end state and create a roadmap to achieve it.`,
      icon: <IntegrationIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Master and Reference Data Management',
      description: `Introducing new platforms that fundamentally redefine the way clients see data is a very involved activity. This is applicable especially in situations where clients decide to establish Master Data Management (MDM) platforms and service the IT applications through them. Our software integration and development services help you establish MDMs and integrate your existing technology stack into a seamless, efficient system. We offer a range of services, including software integration, API development, and custom software development to help you achieve your business goals.`,
      icon: <DataIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Data Analytics Consulting',
      description: `We help our clients leverage the power of data to drive business growth and innovation. Our team of experts has extensive experience in data analytics, data warehousing, and business intelligence. We introduce industry experts who share their insights and help craft the BI roadmap for our clients that guarantees success in reaching the planned end state.`,
      icon: <AnalyticsIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Digital Transformation Consulting',
      description: `We help our clients navigate the complex and rapidly changing digital landscape to drive business growth and innovation. Our team of experts has extensive experience in digital strategy, customer experience design, and more.`,
      icon: <DigitalTransformationIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Cloud Computing Services',
      description: `Cloud Migration is one of the hottest topics in an enterprise IT strategy. New application or product development finds cloud-based application design and deployment to be more attractive. We help our clients leverage the power of cloud computing to improve their operational efficiency, reduce costs, and enhance their ability to innovate. Our team has extensive experience in designing and implementing cloud solutions that are tailored to meet our clients' specific needs.`,
      icon: <CloudIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
    {
      title: 'Managed IT Services',
      description: `We provide a range of managed IT services to help our clients improve their IT operations and reduce costs. Services range from supporting Production Operations on deployed applications, Metadata Platforms Management, Master Data Management. Our team of experts can handle everything from day-to-day support to strategic planning and implementation.`,
      icon: <ManagedServicesIcon sx={{ fontSize: 50, color: '#d176db' }} />
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 3, md: 5 }, marginTop: 8, backgroundColor: 'white' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#d176db', textAlign: 'center', marginBottom: 5, fontWeight: 'bold' }}
      >
        Expert IT Consulting Services by R Cube
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: 2,
                boxShadow: 3,
                transition: 'transform 0.2s, box-shadow 0.2s',
                borderRadius: 2,
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                  {service.icon}
                </Box>
                <Typography variant="h6" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold' }}>
                  {service.title}
                </Typography>
                <Typography variant="body1" sx={{textAlign: "justify" }}>{service.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TechnologyConsulting;
