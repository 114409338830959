import React, { useState } from 'react';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Service1 from '../../assets/Service1.jpg';
import Service2 from '../../assets/Service2.jpg';
import Service3 from '../../assets/Service3.jpg';
import Service4 from '../../assets/Service4.png';

const ServicesOffered = () => {
  const services = [
    {
      title: 'Technology Consulting',
      description: `We offer technology consulting services on full lifecycle as well as fractional engagements requiring specialized skills. 

      We are highly experienced in creating global and regional products and services. 
      
      Our team of consultants are skilled in translating business requirements into technology solutions by employing tools that are conducive to the client's environment and promises a sustained service. 
      
      We build solutions with a product mindset. We instill the same philosophy in our clients and empower the team throughout the engagement. 
      
      
      
      Our methodology includes periodical program oversights by external Industry and technology experts. This helps in risk mitigation. We offer expertise with On Premises traditional software stack as well as cloud native technology solutions.`,
      buttonText: 'FIND OUT MORE',
      image: Service1,
      link: "/services/strategic-consulting", // Path to the page for Technology Consulting
    },
    {
      title: 'Data Governance Lifecycle & Framework',
      description: `Data Governance is currently the most discussed topic in Finance and other regulated industries. Customer or User Data Privacy is front and center in every regional regulatory mind. 

 

      A Mature Data Governance Process addresses facets linked with People, Process and tooling that supports the ecosystem. Each industry tries to bite pieces of this pie differently. 
      
      
      
      At R Cube Consulting, our team has deep knowledge of the underpinnings needed for a sound and comprehensive data governance ecosystem. `,
      buttonText: 'LEARN MORE',
      image: Service2,
      link: "/services/data-governance", // Path to the page for Data Governance
    },
    {
      title: 'Business Operations Strategy and Planning',
      description: `R Cube Consultants combine Big Picture objective and manageable & traceable tasks with pace and scale fit for an organization's size and culture. 

      Strategy and Planning activities must be backed with measurable business metrics and monetized value of change, where applicable.
      
      R Cube partners and empowers the client stakeholders to reach business stated objectives in a collaborative fashion. `,
      buttonText: 'FIND OUT MORE',
      image: Service3,
      link: "/services/strategic-consulting", // Path to the page for Business Operations
    },
    {
      title: 'Staffing and Resourcing',
      description: `We help clients find the talent that match their operational and cultural needs. The assignment tenure and availability (Remote, Hybrid and On-site) are always kept in mind. We search and screen candidates via our panel of experts to present the best fit candidate with a singular goal of bringing efficiency and trust back into the hiring process.`,
      buttonText: 'FIND OUT MORE',
      image: Service4,
      link: '/', // Path to the page for Staffing and Resourcing
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 5, marginTop: 8 }}>
      <Typography variant="h4" gutterBottom sx={{ color: "#d176db", textAlign: "center", marginBottom: 5 }}>
        Our Services
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} md={6} key={index}>
            <ServiceCard service={service} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const ServiceCard = ({ service }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getShortDescription = (description) => {
    const maxLength = 200;
    if (description.length > maxLength && !expanded) {
      return description.substring(0, maxLength) + '...';
    }
    return description;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        component="img"
        src={service.image}
        alt={service.title}
        sx={{ width: '100%', height: 'auto', marginBottom: 2 }}
      />
      <Typography variant="h5" gutterBottom sx={{ color: "#d176db" }}>
        {service.title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: 2 }}>
        {getShortDescription(service.description)}
      </Typography>
      <Button onClick={toggleExpanded} sx={{ color: "#d176db", marginBottom: 2 }}>
        {expanded ? 'Read Less' : 'Read More'}
      </Button>
      {/* Link the button to the specified route */}
      <Button
        component={Link}
        to={service.link}
        variant="contained"
        sx={{ backgroundColor: "#d176db", color: "white", '&:hover': { backgroundColor: "#b15bb5" } }}
      >
        {service.buttonText}
      </Button>
    </Box>
  );
};

export default ServicesOffered;
