import React from 'react';
import { Box, Typography, TextField, Button, Link } from '@mui/material';

const ContactUs = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 3, md: 5 }, marginTop: 8 }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold', textAlign: 'center' }}>
        Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginBottom: 3 }}>
        Drop us a line!
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField id="name" label="Name" variant="outlined" fullWidth margin="normal" required />
          <TextField id="email" label="Email" variant="outlined" fullWidth margin="normal" required />
          <TextField
            id="message"
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            required
          />
          <Button type="submit" variant="contained" color="primary" size="large" sx={{ marginTop: 2 }}>
            SEND
          </Button>
        </Box>
      </form>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginTop: 3 }}>
        This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginTop: 5 }}>
        Better yet, see us in person! Let us fix a time to meet in person.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginTop: 1 }}>
        <Link href="mailto:RCube@RCubeConsulting.com" target="_blank" rel="noopener noreferrer" color="primary">
          RCube@RCubeConsulting.com
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
        40 Catskill Court, Belle Mead, New Jersey 08502, United States
      </Typography>
    </Box>
  );
};

export default ContactUs;
