import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";

import SC1 from "../../assets/SC1.svg";
import SC2 from "../../assets/SC2.svg";
import SC3 from "../../assets/SC3.png";
import SC4 from "../../assets/SC4.svg";
import SC5 from "../../assets/SC5.svg";

const services = [
  {
    title: "IT Strategy & Planning",
    description:
      "Our IT strategy consulting services help you align your technology investments with your business goals. We can help you develop a roadmap for digital transformation, optimize your IT operations, and identify new opportunities.",
    image: SC1,
  },
  {
    title: "Cloud Infrastructure Migration",
    description:
      "We provide scalable and cost-effective cloud solutions for businesses of all sizes. Our team of experts can help you plan and migrate your applications and data to the cloud, ensuring maximum performance and security.",
    image: SC2,
  },
  {
    title: "Managed Services Planning",
    description:
      "Our managed DG services allow you to focus on your core business while we handle your DG Upload and maintenance & infrastructure. We provide 24/7 monitoring, Metadata Quality reporting, proactive maintenance, and helpdesk support to keep collaborating users and systems running smoothly.",
    image: SC3,
  },
  {
    title: "Data Governance and Management",
    description:
      "We help business sponsors realize their vision for a sound information network environment that reduces risks of data use while innovating to augment their revenue. Our team can help you collect, analyze, and visualize your data to uncover operations and cultural gaps, trends & patterns in aid of establishing governance goals and policies.",
    image: SC4,
  },
  {
    title: "Information Architecture & Data Security Audits",
    description:
      "We help business sponsors realize their vision for a sound information network environment that reduces risks of data use while innovating to augment their revenue. Our team can help you collect, analyze, and visualize your data to uncover operations and cultural trends & patterns in aid of establishing governance goals and policies.",
    image: SC5,
  },
];

const StrategicConsulting = () => {
  return (
    <Box
      sx={{ flexGrow: 1, padding: { xs: 3, md: 5 }, marginTop: 8, backgroundColor: "#f5f5f5" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: "#d176db",
          textAlign: "center",
          marginBottom: 5,
          fontWeight: "bold",
        }}
      >
        Strategic Consulting Services offered by R Cube
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            md={index === services.length - 1 ? 12 : 6} // Last item spans full width on medium screens
            key={index}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
                boxShadow: 3,
                transition: "transform 0.2s, box-shadow 0.2s",
                borderRadius: 2,
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={service.image}
                  alt={service.title}
                  sx={{ width: "50%", height: "auto", marginBottom: 2, margin: '0 auto' }}
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#d176db", fontWeight: "bold" }}
                >
                  {service.title}
                </Typography>
                <Typography variant="body1" sx={{textAlign: "justify" }}>{service.description}</Typography>
              </CardContent>
              
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StrategicConsulting;
