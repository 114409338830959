import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#f6f6f6', color: '#000000', py: 2, textAlign: 'center', width: '100%', marginTop: 5 }}>
      <Typography variant="body1">
        Copyright © 2024 R Cube Consulting - All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
 