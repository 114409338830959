import React from 'react';
import { Box, Typography, Grid, Avatar } from '@mui/material';

const MentoringInternship = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 3, md: 5 }, marginTop: 8 }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold', textAlign: 'center' }}>
        Mentoring and Internship
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold', textAlign: 'center', margin: 5}}>
        Empowering the young and hopefuls
      </Typography>
      <Typography variant="body1" gutterBottom>
        At R Cube Consulting, we encourage team members to dedicate a portion of their time to mentoring college graduates and young professionals who are starting their career in Information Technology. We pair up team members to form Mentor-Mentee relationships based on the aspirations and availability of the experts in that field.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We also have a bi-annual intern intake program that is tied with the universities in India. We allow final year graduate studies students to shadow real-life programs and initiatives to expose them to the workings of the consulting world.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: '#d176db', fontWeight: 'bold', textAlign: 'center', margin: 5}}>
        The success stories.
      </Typography>

      <Grid container spacing={3} sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={4}>
          <Avatar
            alt="Gul Muazzam Faize"
            src="https://via.placeholder.com/150"
            sx={{ width: 120, height: 120, margin: '0 auto', display: 'block' }}
          />
          <Typography variant="h6" align="center" gutterBottom>
            Gul Muazzam Faize
          </Typography>
          <Typography variant="body2" align="center">
            Software Intern
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Avatar
            alt="Abhishek Gharat"
            src="https://via.placeholder.com/150"
            sx={{ width: 120, height: 120, margin: '0 auto', display: 'block' }}
          />
          <Typography variant="h6" align="center" gutterBottom>
            Abhishek Gharat
          </Typography>
          <Typography variant="body2" align="center">
            Software Intern
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Avatar
            alt="Bhagesh Ghuge"
            src="https://via.placeholder.com/150"
            sx={{ width: 120, height: 120, margin: '0 auto', display: 'block' }}
          />
          <Typography variant="h6" align="center" gutterBottom>
            Bhagesh Ghuge
          </Typography>
          <Typography variant="body2" align="center">
            Software Intern
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MentoringInternship;
